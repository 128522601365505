<template>
  <Page>
    <div class="max-w-lg p-4 bg-white card">
      <h2 class="text-lg font-bold">
        Details
      </h2>
      <div class="mt-4 pb-4">
        <p>{{ profile?.first_name }} {{ profile?.last_name }}</p>
        <p>{{ profile?.email }}</p>
      </div>
      <DefaultProjectSelect v-if="hasMultipleProjects" />
    </div>
    <div
      v-if="agreementData.agreementLink"
      class="max-w-lg p-4 mt-4 bg-white card"
    >
      <h2 class="text-lg font-bold">
        Merchant Agreement
      </h2>
      <table class="w-full mt-4">
        <thead class="text-gray-400">
          <tr>
            <th class="text-left">
              Agreed on
            </th>
            <th />
          </tr>
        </thead>
        <tbody class="py-1">
          <tr>
            <td class="text-gray-600">
              {{ agreementData.agreedAt }}
            </td>
            <td class="flex justify-end">
              <a
                :href="agreementData.agreementLink"
                class="button button--secondary button--xs"
                target="_blank"
              >OPEN</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      v-if="agreementData.feeScheduleLink"
      class="max-w-lg p-4 mt-4 bg-white card"
    >
      <h2 class="text-lg font-bold">
        Fee Schedule
      </h2>
      <table class="w-full mt-4">
        <thead class="text-gray-400">
          <tr>
            <th class="text-left">
              Agreed on
            </th>
            <th />
          </tr>
        </thead>
        <tbody class="py-1">
          <tr>
            <td class="text-gray-600">
              {{ agreementData.agreedAt }}
            </td>
            <td class="flex justify-end">
              <a
                :href="agreementData.feeScheduleLink"
                class="button button--secondary button--xs"
                target="_blank"
              >OPEN</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </Page>
</template>

<script setup lang="ts">
import {
  ref, Ref, inject, onMounted,
} from 'vue';
import { useRoute } from 'vue-router';
import { RoleControlConfig } from '@/types';
import { AppState } from '@/pages/app/api/get_app_state';
import { Profile } from '../api/get_profile';
import { AgreementDetails, getAgreement } from './api';
import Page from '../../components/page.vue';
import { roleConfig } from './role_configurations';
import DefaultProjectSelect from './components/default_project/index.vue';

const agreementData = ref<AgreementDetails>({
  agreementLink: '',
  feeScheduleLink: '',
  agreedAt: '',
});

const projectId = useRoute().params.projectId as string;
const profile = inject<Ref<Profile | null>>('profile');
const appState = inject<Ref<AppState>>('state') as Ref<AppState>;
const roleName = appState.value.projects[projectId].currentUserRoleName;

const shouldGetAgreement = (roleName: string) => {
  const defaultPermission: RoleControlConfig = { hidden: true, restricted: true };
  const config = roleConfig.getAgreements[roleName] ?? defaultPermission;
  return !(config.hidden || config.restricted);
};

const hasMultipleProjects = Object.keys(appState.value.projects).length > 1;

onMounted(async () => {
  if (shouldGetAgreement(roleName)) {
    try {
      agreementData.value = await getAgreement(projectId);
    } catch (error) {
      console.log(error);
    }
  }
});
</script>
